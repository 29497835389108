





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Ticket from '@improve/common-utils/src/model/Ticket';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import BaseTicketCard from '../../ticket/BaseTicketCard.vue';

@Component({
  name: 'ImplementedTab',
  components: {
    BaseTicketCard
  }
})
export default class ImplementedTab extends Vue {
  @Action getAllTickets!: ActionMethod;

  tickets = new Array<Ticket>();

  async created(): Promise<void> {
    const searchBy = new TicketSearchParams();
    searchBy.status = [ImproveTicketStatus.IMPLEMENTED];
    this.tickets = await this.getAllTickets(searchBy);
  }
}
